import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class investApis {
  deposit = async data => {
    const formData = new FormData()
    Object.keys(data).forEach(element => formData.append(element, data[element]))
    return await axiosIns.post('investment/deposit/store', formData)
  };

  depositHistory = async (data = {}) => await axiosIns.get('investment/deposits', objectToUrlParam(data))
}
