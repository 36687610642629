<template>
  <div>
    <b-card
      v-for="deposit in data"
      :key="deposit.id"
      class="text-center px-4 my-2"
      style="margin: auto;"
    >
      <div class="d-flex justify-content-center align-items-center mb-3">
      <!-- <b-badge
        variant="light"
        class="p-3"
        style="font-size: 2rem;"
      >
        ✔
      </b-badge> -->
      </div>
      <h1
        style="font-size: 50px;"
        class="mb-3"
      >
        {{ numberWithCommas(toFixed(deposit.amount,deposit.mode ==='USD'?2:0)) }} {{ $t(deposit.mode) }}
      </h1>
      <div style="font-size: 20px;">
        <div
          class="d-flex justify-content-center flex-wrap"
          style="gap: 50px;"
        >
          <p>{{ $t('status') }}: <span :class="statusClass(deposit.status)">{{ $t(deposit.status) }}</span></p>
          <p>
            {{ $t('duration') }}: {{ deposit.duration }} {{ $t('month') }}
          </p>
          <p>
            {{ $t('starts-at') }}: {{ moment(deposit.created_at).format('DD/MM/YYYY') }}
          </p>
          <p> {{ $t('ends-at') }}: {{ moment(deposit.created_at).add(deposit.duration , 'months').format('DD/MM/YYYY') }}</p>
        </div>
        <!-- <div
          class="my-3 d-flex justify-content-center flex-wrap"
          style="gap: 50px;"
        >
          <p>{{ $t('duration') }}: {{ deposit.duration }}</p>
          <p>{{ $t('payment') }}: {{ paymentFrequency }}</p>
          <p>{{ $t('annual-interest') }}: {{ annualInterest }}%</p>
        </div> -->
        <div
          class="d-flex justify-content-around flex-wrap"
        >
          <div class="text-center my-1">
            <span>{{ $t('annual-interest') }}</span>
            <br>
            <strong v-if="findedPlan(deposit.mode,deposit.amount,deposit.duration)">{{ findedPlan(deposit.mode,deposit.amount,deposit.duration).annual_interest }}%</strong>
            <small v-else>{{ $t('complete-the-form-to-calculate') }}</small>
          </div>
          <div class="text-center my-1">
            <span>{{ $t('total-interest') }}</span>
            <br>
            <strong v-if="findedPlan(deposit.mode,deposit.amount,deposit.duration)">{{ findedPlan(deposit.mode,deposit.amount,deposit.duration).annual_interest/12*deposit.duration }}%</strong>
            <small v-else>{{ $t('complete-the-form-to-calculate') }}</small>
          </div>
          <div class="text-center my-1">
            <span>{{ $t('payment') }}</span>
            <br>
            <strong v-if="findedPlan(deposit.mode,deposit.amount,deposit.duration)">{{ $t('every') }} {{ findedPlan(deposit.mode,deposit.amount,deposit.duration).payment }} {{ $t('month') }}</strong>
            <small v-else>{{ $t('complete-the-form-to-calculate') }}</small>
          </div>
        </div>
      </div>
      <b-alert
        v-if="deposit.status"
        show
        variant="warning"
        class="p-2"
      >
        <p style="text-align: start;">
          لطفا منتظر بررسی و تایید سرمایه گذاری خود باشید. این عملیات توسط اوپراتور های ما تا 2 روز آینده انجام خواهد شد. از صبر و شکیبایی شما ممنونیم.
        </p></b-alert>
      <div class="mb-4">
      <!-- <b-button
        variant="success"
        class="w-100 mb-2"
      >
        {{ $t('invest') }}
      </b-button> -->
      <!-- <b-button
        variant="info"
        class="w-100 mb-2"
      >
        {{ $t('extend') }}
      </b-button> -->
      <!-- <b-button
        variant="outline-secondary"
        class="w-100 mb-2"
      >
        {{ $t('payment-history') }}
      </b-button> -->
      <!-- <b-button
        variant="outline-secondary"
        class="w-100"
      >
        {{ $t('withdraw') }}
      </b-button> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment-jalaali'
import { dollarPlans, rialPlans } from '../../consts.ts'
import InvestApis from '../../service/apis'
import { numberWithCommas, toFixed } from '@/utils'

const invest = new InvestApis()
export default {
  data() {
    return {
      data: [],
      moment,
      numberWithCommas,
      toFixed,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      invest.depositHistory().then(res => {
        this.data = res.data.results.data
      })
    },
    findPlan(amount, duration, plansList) {
      // eslint-disable-next-line no-restricted-syntax
      for (const plan of plansList) {
        if (amount >= plan.min_balance && amount <= plan.max_balance) {
          const durationPlan = plan.durations.find(d => d.duration === duration)
          if (durationPlan) {
            return {
              annual_interest: durationPlan.annual_interest,
              payment: durationPlan.payment,
            }
          }
        }
      }
      return null // Return null if no matching plan is found
    },
    findedPlan(currency, amount, duration) {
      const p = this.findPlan(currency === 'USD' ? amount : amount / 10000000, duration, currency === 'USD' ? dollarPlans : rialPlans)
      return p
    },
    statusClass(status) {
      return status === 'pending' ? 'text-warning' : 'text-success'
    },

  },
}
</script>

  <style>
  .text-warning {
    color: #ffc107;
  }

  .text-success {
    color: #28a745;
  }
  </style>
