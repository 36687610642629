<template>
  <div>
    <MyPlanCard />
  </div>
</template>

<script>
import MyPlanCard from './components/myPlanCard.vue'

export default {
  components: { MyPlanCard },
}
</script>
